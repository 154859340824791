/* Global styles */




* {
    margin: 0;
    overflow-x: hidden;
    --secondary--text-color: #3B3B38;
    --secondary-color: rgb(0,164,166);
    --button-radius: 10px;
    --about-height: 22vw;
    --about-content-font-size:1.4vw;
    --about-header-font-size:2.3vw;
  }
  
  .landing-container {
    width: 100vw;
    overflow: hidden;
    justify-content: end;
    align-items: center;
    text-align: center;
    button{
      text-align: center;
      font-size: 30px;

    }

  }

  .full-accordian{
    display: flex;
    flex-direction: column;
   
  }
  
  .intro-section {
    position: relative;
    width: 100%;
    height: 0px; // Set height to 0 to use padding for aspect ratio
    padding-top: 40%; // 16:9 aspect ratio (9 / 16 * 100%)
    overflow: hidden;
    
  }
  .intro-section::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); // Adjust transparency as needed
    z-index: 1; // Ensure it's above the image but below the text
  }
  
  .intro-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
  }

  .section-images{
    height: 100%;
    overflow-y: hidden;
  }
  
  
  .headshot {
    position: absolute;
    top: 40px;
    left: 40px;
    width: 15vw; // Adjust based on preference, vw makes it responsive
    border-radius: 50%;
    border: 2px solid black;
    z-index: 2;
    animation : hoverAnimation 4s ease-in-out infinite;
    // box-shadow: 0 10px, 10px rgba(0, 0, 0, 0.5);
  
  }

  @keyframes hoverAnimation{
    0%, 100%{
      transform: translateY(0px);
      box-shadow: 5px 15px 20px rgba(0,0,0,0.6); /* Closer shadow */
    }
    50%{
      transform: translateY(2px);
      box-shadow: 5px 15px 20px rgba(0,0,0,0.85); /* Closer shadow */
    }
  }

  h1 {
    margin: 0;
    font-size: 5vw; // Example font size, adjust as needed
    line-height: 1.2; // Adjust this based on your font size to prevent excessive movement
    // Ensure line height is not too high to avoid pushing other content away
    // width: 120%
  }
  
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // Ensures the content block itself is centered and content within it is also centered
    color: white;
    text-shadow:  
   
    2px  2px 0 rgba(0, 0, 0, 0.5); /* Outline effect */;
    
    

    h2{
     font-size: 5vw;   
    }
    p{
        font-size: 3vw;
    }
    button{
        background-color: white;
        position:  relative;
        border:none;
        width: 12vw;
        height: 3vw;
        margin-top: 2%;
        color: black;
        font-size: 1vw;
        border-radius: var(--button-radius);
        overflow: hidden;

    }
    button:hover{
        background-color: rgba(200, 200, 200, 0.5);
        cursor: pointer; 
        transition:  0.2s
    }

    button:after{
      content: ">>";
      position: absolute;
      right: 10%; /* Adjust as needed */
      top: 0;
      bottom: 0;
      opacity: 0;


    }




  }

  
  .about{
    text-align: center;
   
    margin-top :40px;
    padding: 0;
    color: var(--secondary--text-color);
    font-size: 4vw;
   

    hr{
      border-top: double  var(--secondary--text-color);;
      margin: 10px 40% 10px 40%;
      z-index: 1;
      // position: relative;
      // margin-top: 40px;
      
      }
    hr::after{
      content: "§";
      position: absolute;
      transform: translateX(-80%) translateY(-60%) ;
      font-size: 0.4em;
      background-color: white;
      z-index: 2;

      // padding-left: 5px;
      // padding-right: 5px;


    }
  }



.education{
  display:flex;
  margin-top: -2%;
  padding-left: 5%;
  padding-right: 5%;
  align-items: center;
  justify-content: center;
  height: var( --about-height);
  color: var(--secondary--text-color);
  img{
    flex:5.2;
  }
  div{
    flex:4.8;
    align-items: center;
    justify-content: center;
    
    h3{
      text-align: center;
      font-size: var(--about-header-font-size);
    }
    
    text{
      color: var(--secondary--text-color);
      // color: green;
      text-align: left;
      font-size: var(--about-content-font-size);
      line-height:  var(--about-header-font-size);
    }
    button{
        text-align: center;
        margin-left: 28%;
      }

      
  }
  button{
    margin-top: 5%;
    background-color: var(--secondary-color);
    // background-color: green;
    color: white;
    border: none;
    width: 15vw;
    height: 3vw;
    border-radius: var(--button-radius);
    font-size: var(--about-content-font-size);
  }
  
button:hover{
    background-color: rgba(53, 167, 255);
    cursor: pointer; 
    transition:  0.2s
}
}
.professional{
  --flex-amount:4.75;
  display:flex;
  margin-top: -2%;
  padding-left: 5%;
  padding-right: 5%;
  align-items: center;
  height: calc( 250px + var( --about-height));
  color: var(--secondary--text-color);
  overflow: hidden;
  img{
    flex:var(--flex-amount);

  }
  div{
      flex: calc(10 - var(--flex-amount));
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      h3{
        
        text-align: center;
        font-size: var(--about-header-font-size);
      }

      text{
        color: var(--secondary--text-color);
        // color: green;
        font-size: var(--about-content-font-size);
        line-height:  var(--about-header-font-size);
        margin-right: 2%;
      }
      button{
        margin-top: 5%;
        background-color: var(--secondary-color);
        // background-color: green;
        color: white;
        border: none;
        width: 15vw;
        height: 3vw;
        border-radius: var(--button-radius);
        font-size: var(--about-content-font-size);
      }
      
    button:hover{
        background-color: rgba(53, 167, 255);
        cursor: pointer; 
        transition:  0.2s
    }
  }
}

.personal-projects{

  display:flex;
  margin-top: 2%;
  padding-left: 5%;
  padding-right: 5%;
  align-items: center;
  height: calc( 150px + var( --about-height));
  color: var(--secondary--text-color);
  
  img{
    flex:5.3;
    height: 100%;
  }
  div{
      flex:4.7;
      display:flex;
      flex-direction: column;
      align-items: center;
      
      h3{
        text-align: center;
        font-size: var(--about-header-font-size);
      }

      text{
        color: var(--secondary--text-color);
        // color: green;
        font-size: var(--about-content-font-size);
        line-height:  var(--about-header-font-size);
      }
      button{
        margin-top: 5%;
        background-color: var(--secondary-color);
        // background-color: green;
        color: white;
        border: none;
        width: 12vw;
        height: 3vw;
        border-radius: var(--button-radius);
        font-size: var(--about-content-font-size);
      }
      
      
    button:hover{
        background-color: rgba(53, 167, 255);
        cursor: pointer; 
        transition:  0.2s
    }
  }
}

.socials {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img{
    width:60px;
    margin: 10px;
  }
}
.social-section{
  text-align: center;
  justify-content: center;
  align-items: center;
  h1{
    color: var(--secondary--text-color);
    font-size: 4vw;
    
  }
  hr{
 
    border-top: double  var(--secondary--text-color);
    margin: 10px 40% 10px 40%;
    z-index: 1;
    // position: relative;
    // margin-top: 40px;
    
    }
  hr::after{
    content: "§";
    position: absolute;
    transform: translateX(-50%) translateY(-61%);
    font-size: 0.4em;
    background-color: white;
    z-index: 2;

    // padding-left: 5px;
    // padding-right: 5px;


  }
}

.summary  {
  width: auto;
  justify-content: center;


  text-align: center;


  h2{
    text-align: center;
    font-size: var(--about-header-font-size);
  }
}




.MuiAccordionSummary-root {
  width: 70%; /* Ensures it takes the full width of its parent */
  justify-content: center;
  margin: auto; /* Center align */
  
}


.MuiAccordionSummary-content{

  padding-left: 30vw;

}

